.large-heading{
    font-size: 20px;
}

.largest-heading{
    font-size: 26px;
}

.text-1{
    font-size: 14px;
}