body {
  /* background-color: #f7f7f7; */
  /* font-family: 'Raleway', sans-serif; */
  /* font-family: "Roboto Condensed"; */
}
html {
  overflow-x: hidden;
  font-family: 'Poppins', sans-serif;
}
h1 {
  /* font-family: "Roboto Condensed"; */
/* font-family: 'Lato', sans-serif; */
font-family: 'Poppins', sans-serif;

}
p {
  /* font-family: "Roboto Condensed"; */
font-family: 'Lato', sans-serif;
font-family: 'Poppins', sans-serif;

}
h2 {
  /* font-family: "Roboto Condensed"; */
font-family: 'Lato', sans-serif;
font-family: 'Poppins', sans-serif;

}
div {
  /* font-family: "Roboto Condensed"; */
  /* font-family: 'DM Mono', monospace; */
}
.alertpopup {
  width: 60%;
}

.updiv {
  /* margin-top: 5%; */
}
.updivlogin {
  margin-top: 5%;
}
.authordiv {
  background: #65e671;
  width: 52px;
  padding-left: 18px;
  border: 2px solid;
  font-size: 34px;
}
.left-align{
  padding-left: 15%;
  /* padding-right: 10%; */
}

.left-align-margin{
  margin-left: 15%;
}
.bloghome-themes-div{
  width: 100%;
  overflow-x: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.bloghome-themes-div::-webkit-scrollbar {
  display: none;
}
.blogCardCol{
  /* margin-bottom: "20px"; */
}

@media screen and (max-width: 600px) {
  .updiv {
    /* margin-top: 20%; */
  }
  .updivlogin {
    margin-top: 14%;
  }
  .alertpopup {
    width: 85%;
  }
  .left-align{
    padding-left: 0%;
    /* padding-right: 10%; */
  }
  
.left-align-margin{
  margin-left: 0%;
}
}
@media screen and (max-width: 1000px) and (min-width: 600px) {
  .updiv {
    /* margin-top: 8%; */
  }
  .updivlogin {
    margin-top: 8%;
  }
}

/* BLUE-LIGHT THEME */
.shadowcard {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.blog-col {
  max-width: 48%;
}

/* login input groups */
.buttonb {
  width: -webkit-fill-available;
  background: black;
  border-radius: 0px;
  border: none;
}
.buttonb:hover {
  background: #404040;
}
.inputb {
  border-radius: 0px;
  background: white;
  border: 1px solid;
  color: black;
  /* border: 1px solid black; */
}
.inputb:active {
  background: none;
  border-bottom: 2px solid;
}
.inputb:-internal-autofill-selected {
  /* background-color: rgb(232, 240, 254) !important; */
  background-color: none;
  background-image: none !important;
  color: rgb(0, 0, 0) !important;
}
.form-control:focus {
  border-color: none;
  border: none;
  transition: 0.1s;
  background: none;
  box-shadow: 0 0 0 0.1rem rgba(0, 0, 0, 0.507);
}
.loginfoot {
  background: whitesmoke;
  height: 7vh;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.loginfootp {
  font-size: 1.3rem;
  text-align: center;
}
/*--------------- profile */
.coverimg {
  width: 50%;
  height: 60%;
  border-radius: 50%;
  margin-left: 30%;
  border: 2px solid grey;
  padding: 2px;
}
.coverp {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 1rem;
  color: dimgrey;
}
.coverh1 {
  font-size: 1.5rem;
}

/* boghome */
.blogcard {
  /* border: 1px solid; */
  height: 100%;
  /* padding: 15px; */
}
.border-none{
  border: none;
}
.BlogContentPadded{
  padding: 10px 8px 5px 8px;
}

.blogcard2 {
  border: none;
}
.themeborder{
  border: 1px solid;
}
.blogcardimg {
  width: 100%;
  height: 120px;
  object-fit: cover;
  transition: 0.1s;
  /* padding-top: 15px; */
}

.datablogcard {
  width: 50%;
  padding-top: 0;
}
.bloghomecard:hover .blogcardimg {
  border: 2px solid var(--primary-color);
  padding: 3px;
  border-radius: 12px;
}

.bloghomecard:hover .blogcardtitle{
  color: var(--darker-primary);
  cursor: pointer;
}

.faded{
  color: var(--fadeout-color);
}
.bottomborder{
  border-bottom: 2px solid black;
}
.blogcard-padding{
  padding-top: 10px;
  padding-bottom: 20px;
}
.custom-hr{
  height: 1px;
  background: #b6b6b6;
  /* width: 90%; */
}
.blogpopcardtitle:hover {
  color: #009688;
  cursor: pointer;
}
.blogright {
  float: right;
  position: absolute;
  bottom: 28px;
  right: 4rem;
}
.blogrightnodejs {
  border: 1px solid;
  padding: 2px 4px 2px 4px;
  /* font-weight: bold; */
  width: fit-content;
}
.blogleft {
  color: #504e4e;
}

.blogpopcard {
  border: none;
  width: 20rem;
}
.blogpopcardtitle {
  font-size: 1.5rem;
}
.blogpopcardpara {
  font-size: 13px;
  color: grey;
}
.bdiv {
  width: 80%;
  margin: auto;
}

.scrollmenu {
  overflow: auto;
  white-space: nowrap;
}
.scrollmenu::-webkit-scrollbar {
  display: none;
}
.blogthemecard {
  display: inline-block;
  height: 9rem;
  width: 17rem;
  background-color: lightskyblue;
  border: none;
  box-shadow: 1px 2px 4px grey;
  margin-right: 2%;
}
.blogthemecardtext {
  color: white;
  position: absolute;
  /* top: 0; */
  right: 0;
  bottom: 0;
  left: 0;
  /* font-weight: 700; */
  font-size: 2rem;
}
img {
  width: 100%;
}

.react {
  background-image: url("./components/react.jpg");
  /* background-image: url("https://i.ibb.co/f8PRyWW/461264-react-JS-Facebook-Java-Script-minimalism-artwork-simple-background-748x421-1.jpg"); */
}
.node {
  background-image: url("./components/node.jpg");
  /* background-image: url("https://i.ibb.co/GPhYcgk/461263-node-js-Java-Script-748x421-1.jpg"); */
}
.ai {
  background-image: url("./components/ai.jpg");

  /* background-image: url("https://i.imgur.com/Mn77tq0.jpg"); */
}
.js {
  background-image: url("./components/js.jpg");

  /* background-image: url("https://i.imgur.com/SGlnZ8C.jpg"); */
}
.review {
  background-image: url("./components/brain.jpg");

  /* background-image: url("https://i.imgur.com/m2vx2YA.jpg"); */
}
.dsalgo {
  background-image: url("./components/ds.jpg");

  /* background-image: url("https://i.imgur.com/bxWntHc.jpg"); */
}

@media screen and (max-width: 500px) {
  .blogright {
    right: 2rem;
  }
  .blogcardtitle {
    /* font-size: 1.5rem; */
  }
  .blogleft {
    font-size: 13px;
  }
}

/* main blog / particular blog */
.mainblogimg {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
}
.mainblog {
  padding-left: 27%;
  display: flex;
  padding-top: 6rem;
}
.mainblogleftp {
  padding-left: 16px;
  padding-top: 2%;
  /* color: #585858; */
  font-size: 15px;
}
.mainblogleftpdown {
  padding-left: 27%;
  padding-top: 2%;
}
.mainblogcontent {
  padding-left: 15px;
  padding-right: 15px;
  font-size: 18px;
}
.blogcontent {
  font-size: 1.4rem;
  /* word-spacing: 0.2rem; */
  padding-right: 3rem;
}
.mainblogh2 {
  font-size: 4rem;
  font-weight: bold;
  margin-bottom: 2rem;
  margin-top: 2rem;
}
.timming {
  /* display: none; */
  color: grey;
}
.views {
  background: #cdedf1;
  width: fit-content;
  color: #329688;
  border-radius: 6px 6px 0px 0px;
  padding: 2px;
}
@media screen and (max-width: 600px) {
  .mainblogh2 {
    font-size: 3.5rem;
  }
  .bdiv {
    width: 100%;
  }
  .mainblog {
    padding-left: 5%;
    padding-top: 1rem;
  }
  .mainblogleftpdown {
    display: none;
  }
  .nameblogcard {
    margin-bottom: 0;
  }
  .datablogcard {
    padding-top: 0px;
  }
  .blogcontent {
    padding-right: 0;
    font-size: 1.2rem;
  }
  .blog-col {
    max-width: 100%;
  }
  .new_home {
    box-shadow: 1px -18px 21px -15px #cec9c9;
    border-radius: 66px 50px 0px 0px;
  }
  .new_home_h3 {
    text-align: center;
    padding-top: 3%;
    font-weight: bold;
  }
  .new_profile {
    box-shadow: 1px 21px 21px -15px #cec9c9;
    border-radius: 0px 0px 50px 50px;
  }
}
@media screen and (max-width: 1090px) {
  .blog-col {
    max-width: 100%;
  }
}

.blogpostscardp {
  /* font-family: 'Raleway', sans-serif; */
  font-size: 18px;
  color: #3e3d3d;
}
.blogpostspan {
  color: grey;
  font-size: 14px;
}
.blogpostscardp:hover {
  color: var(--darker-primary);
}
.mainblogimgcontent {
  width: 100%;
}
@media screen and (min-width: 600px) {
  .hrleft {
    display: none;
  }
}
.profilecard {
  margin-top: 1rem;
}

.profilecardrowmain {
  padding-left: 15%;
}
.h2mainprofile {
  padding-left: 15%;
  font-size: 1.5rem;
}
.profilemainhr {
  width: 75%;
}

pre {
  display: block;
  background-color: #f5f5f5;
  padding: 20px;
  font-size: 14px;
  line-height: 28px;
  border-radius: 0;
  overflow-x: auto;
  word-break: break-word;
}

code {
  /* background-color: #f5f5f5; */
  border-radius: 0;
  padding: 3px 0;
  margin: 0;
  font-size: 14px;
  overflow-x: auto;
  word-break: normal;
}
blockquote {
  position: relative;
  margin: 16px 0;
  padding: 5px 8px 5px 30px;
  background: none repeat scroll 0 0 rgba(102, 128, 153, 0.05);
  border: none;
  color: #333;
  border-left: 10px solid #d6dbdf;
}
blockquote p {
  margin: 8px 0;
}

.Profilename {
  background-color: #66e492;
  /* border-radius: 50%; */
  padding-left: 38px;
  width: 100px;
  font-size: 4rem;
  margin: auto;
  border-radius: 2px solid black;
  height: 100px;
  border: 3px solid black;
}
.profileedit {
  margin-left: 28%;
  font-size: 23px;
  background: whitesmoke;
  width: 43%;
  text-align: center;
  padding: 4px 0px 6px 0px;
  margin-top: 2%;
  border-radius: 12px;
}

.mainprofilespan {
  padding: 4px 10px 4px 10px;
  border-radius: 10px;
  border: 2px solid #5d5b5b;
  margin-left: 5px;
  margin-right: 6px;
}
.mainprofilep {
  padding-left: 7%;
  color: #505050;
}

.coreh1 {
  font-size: 1.8rem;
  padding-top: 2%;
  font-weight: bold;
  text-align: center;
}
.loading {
  width: 16%;
  margin: auto;
}
mark {
  background-color: #96f59a;
}
@media screen and (max-width: 600px) {
  .loading {
    width: 48%;
  }
  .profilecardrowmain {
    padding-left: 10%;
  }
  .coreh1 {
    padding-top: 7%;
  }
  .h2mainprofile {
    padding-left: 10%;
  }
  .profilemainhr {
    width: 100%;
  }
  .imglanding {
    display: none;
  }
  .landingskilldiv {
    width: 90%;
  }
}
@media screen and (min-width: 900px) {
  .profilecardrow {
    width: 35rem;
  }
}

/* landing */
.imglanding {
  width: 110vh;
  margin: auto;
}
.styleh1 {
  font-size: 6vh;
  padding-top: 4rem;
}

.landingcontdiv {
  background: #080808;
  color: white;
  padding-bottom: 1rem;
}

.landingh2 {
  font-weight: bold;
  font-size: 5vh;
}
.landingp {
  color: grey;
  font-size: 3rem;
}
.landingimg {
  width: 15%;
  height: 60%;
  border-radius: 50%;
  border: 2px solid grey;
  padding: 3px;
}

.landingskilldiv {
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  width: 60%;
  margin: auto;
}
.landingskillsmalldiv {
  font-size: 3vh;
  background: #dfe0e0;
  border-radius: 20px;
  padding: 2px 11px 2px 11px;
  /* font-weight: bold; */
  margin: 7px;
}
.landingskilldivprofile {
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  margin: auto;
}
.landingskillsmalldivprofile {
  background: #cdedf1;
  padding: 2px 11px 2px 11px;
  margin: 7px;
}
.topland {
  padding-left: 2%;
  color: grey;
  font-size: 3vh;
}

.footerIcons{
  color: var(--primary-color);
  font-size: 28px;
}