body {
  font-family: "Lato", sans-serif;
}
.cl {
  text-decoration: underline;
  color: #4682b4;
}

.header-name{
  background-color: white;
  cursor: pointer;
  color: black;
  text-decoration: none;
  border: 3px solid black;
  padding: 8px 12px 8px 12px;
  font-Size: 18px;
  font-Weight: bold;
  position: relative;
  text-decoration-line: none !important; 
}

.header-name span {
  position: relative;
  z-index: 2;
  text-decoration-line: none; 
  color: black;
}

.header-name:after{
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: var(--darker-primary);
  transition: all .35s;
  text-decoration-line: none; 
}

.header-name:hover:after{
  width: 100%;
}


.navlayout {
  position: fixed;
  width: 100%;
  top: 0;
  background: white;
  z-index: 1002;
  /* box-shadow: 0px -1px 6px grey; */
  box-shadow: 0px -1px 15px 3px #e6e6e6;
  opacity: 0.95;
}


.navlayoutlogin {
  /* position: fixed; */
  width: 100%;
  top: 0;
  background: white;
  z-index: 10000;
  opacity: 0.95;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 40px;
  padding-bottom: 40px;

  /* height: 15rem; */
  border-bottom: 2px solid black;
  animation: FadeIn 1s ease-in-out forwards;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.navlayoutbar {
  position: fixed;
  width: 100%;
  top: 0;
  background: white;
  z-index: 10000;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid black;
  transition: 0.3s;
}

.navHidden{
  transform: translateY(-100px);
}

.navActive{
  transform: translateY(0px);
}

@media (max-width: 600px) {
  .navlayoutlogin {
    padding-left: 15px;
  }
}

@keyframes FadeIn {
  0% {
    background-color: white;
  }

  /* 50% {
    background-color: #ff9a52;
  } */

  100% {
    background-color: #3e90fb;
  }
}


@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 16;
  top: 0;
  left: 0;
  background-color: white;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  box-shadow: 1px 0px 5px 0px rgba(0, 0, 0, 0.2);
}

.sidenav a {
  text-decoration: none;
  color: steelblue;
  display: block;
  transition: 0.3s;
}
.navcontent {
  background-color: #f9f9f9;
  height: 100%;
  color: black;
  border-top: 2px solid #eaeaea;
}

.navcontent a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 14px;
  display: block;
  transition: 0.3s;
  color: black;
  font-family: sans-serif;
  font-size: 1.1rem;
}
.navcontent a:hover {
  color: #6db7fd;
}
.navcontent a:first-child {
  padding-top: 30px;
}

.firsta {
  padding-top: 32px;
}

.navhead a {
  font-size: 22px;
  color: #65cdf0;
}
.navhead div {
  margin-top: 22px;
  margin-left: -13px;
}
.navhead div p {
  margin-bottom: 0px;
}
.navhead div a {
  font-size: 14px;
  text-decoration: underline;
}
.backicon {
  color: #65cdf0;
}
.fa-user {
  font-size: 3em;
  color: #6db7fd;
  margin-bottom: 2vh;
}
.container1 {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

/* h1 {
    text-align: center;
  } */

.navhead h2 {
  font-size: 31px;
  color: white;
  padding-left: 0px;
}
.sidenav a:hover {
  color: lightblue;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}
.navrequest {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 14px;
  display: block;
  transition: 0.3s;
  color: black;
  font-family: sans-serif;
  font-size: 1.1rem;
}

.navmobile {
  color: #607d8b;
  margin-left: 10px;
  padding-top: 9px;
  margin-right: 15px;
  font-size: 30px;
  cursor: pointer;
  float: left;
  display: none;
}
.navright {
  float: right;
  display: none;
  margin-top: -50px;
}
.navrighttags {
  font-size: larger;
  padding-right: 13px;
  padding-top: 15px;
  cursor: pointer;
  color: black;
}
.letsconnect :hover{
  transform: rotate(180deg);
}
@keyframes chamakchallo {
  0% {
      transform: rotate(30deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

@media screen and (min-width: 750px) {
  .navmobile {
    display: none;
  }
  .navright {
    display: flex;
  }
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}

/* span {

  } */

.navhead {
  padding: 8px 8px 8px 32px;
}
.navicon {
  float: right;
  margin-right: 8%;
  /* padding-top: 2px; */
}

.bg {
  /* The image used */
  /* background-image: url("photographer.jpg"); */

  /* backgroundColor :rgba(0,0,0,0.4); */
  /* Add the blur effect */
  filter: blur(2px);
  -webkit-filter: blur(2px);

  /* Full height */
  height: 100%;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.active{
  font-weight: 600;
}

.black {
  color: #009688;
}

.navblogheader {
  font-size: 3rem;
  font-weight: bold;
  color: black;
}

.navBlogDiv{
  margin-top: 3rem;
  text-align: center;
}
.navblogtime{
  font-size: 16px;
  color: #e1e1e1;
}
.navblogauthor{
  font-size: 20px;
  color: #00162d;
}
.navbartheme {
  border: 3px solid;
  padding: 2px 4px 2px 4px;
  /* font-weight: bold; */
  width: fit-content;
  color: #004a9a;
  font-weight: 600;
}