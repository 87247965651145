
.loginbox{
    padding: 11px 13px 11px 13px;
}

@media screen and (max-width:600px)
{
    .leftlogin{
        display: none;
    }
    .rightlogin{
        padding-right: 0px  
    }
    .loginbox{
    }
}

@media screen and (min-width : 500px)
{
    .loginbox{
        width: 34rem;
        margin: auto;
    }
}